span.loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 3em;
  font-family: Arial, Helvetica, sans-serif;
}

span.loading, div[class^='Login-main'], div[class*=' Login-main'] {
  background: url('./background.jpg') !important;
  background-size: cover !important;
  background-position: bottom !important;
}

span.loading::after, div[class^='Login-main']::after, div[class*=' Login-main']::after {
  content: "Photo by Markus Spiske on Unsplash";
  position: absolute;
  right: 1em;
  bottom: 1em;
  color: white;
  font-size: 10px;
  font-family: Arial, Helvetica, sans-serif;
  opacity: 0.5;
}
